.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .home-header {
    min-height: calc(100vh - 21px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
  color: #C16E4C;
  background-color: #fff;
  border-color: #E7B365;
}

a {
  color: rgba(0, 0, 0, 0.65);
}

a:hover {
  color: #E7B365;
}
  